import React from 'react';
import { Carousel, Skeleton } from "antd";
import { shallowEqual, useSelector } from "react-redux";

const BannerCarousel = ({images, fallBackImage, isLoading, setLoading}) => {
  const { settingState } = useSelector((state) => ({ settingState: state.settingData }), shallowEqual);
  const duration = settingState?.ecom?.basic?.slider_duration ?? 2

  return (
    <Carousel fade autoplaySpeed={duration * 1000} dots autoplay>
      {images.length > 0 ? (
        images.map((imageData, index) => {
          const { image, url } = imageData;
          return (
            <div
              key={index}
              className={`slider-content ${url ? 'tw-cursor-pointer' : ''}`}
              style={{ minHeight: "700px" }}
            >
              {isLoading && <Skeleton.Button className="image" active />}
              <img
                className="image"
                onClick={() => {
                  if (url) {
                    const formattedUrl = url.startsWith("https://")
                      ? url
                      : `https://${url}`;
                    window.open(formattedUrl, "_blank");
                  }
                }}
                src={image || fallBackImage}
                alt={`Slide ${index}`}
                onLoad={() => setLoading(false)}
                style={{
                  display: isLoading ? "none" : "block",
                }}
              />
            </div>
          );
        })
      ) : (
        <div className="slider-content" style={{ minHeight: "700px" }}>
          {isLoading && <Skeleton.Button className="image" active />}
          <img
            className="image"
            src={fallBackImage}
            alt="Fallback Logo"
            onLoad={() => setLoading(false)}
            style={{
              display: isLoading ? "none" : "block",
            }}
          />
        </div>
      )}
    </Carousel>
  );
};

export default BannerCarousel;
