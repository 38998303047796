import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import NavCategoriesTemplate1 from './template1';
import NavCategoriesTemplate2 from './template2';
import NavCategoriesTemplate3 from './template3';
import NavCategoriesTemplate4 from './template4';
import NavCategoriesTemplate5 from './template5';
import { BRAND_URL } from "../../../constants/config";
import axios from "axios";

const NavCategories = ({ settingData, templateStyles, activeCategory }) => {
  const [brandsData, setBrandsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const templates = {
    '1': NavCategoriesTemplate1,
    '2': NavCategoriesTemplate2,
    '3': NavCategoriesTemplate3,
    '4': NavCategoriesTemplate4,
    '5': NavCategoriesTemplate5,
  };

  const [position, setPosition] = useState({ top: 0, left: 0 });

  const skeleton = () => (
    <div className="tw-flex tw-items-center tw-p-4 tw-bg-gray-100 tw-animate-pulse tw-space-x-4 tw-absolute tw-w-full tw-top-0">
      {/* Skeleton content */}
    </div>
  );

  const NavCategoriesTemplate = templateStyles?.nav_categories_template || settingData?.nav_categories_template || '1';
  const SelectedTemplate = templates[NavCategoriesTemplate] || skeleton;

  useEffect(() => {
    if (activeCategory?.id) {
      const targetElement = document.getElementById(`category_item_${activeCategory.id}`);
      if (targetElement) {
        const rect = targetElement.getBoundingClientRect();
        setPosition({
          top: rect.bottom,
          left: rect.left,
        });
      }
    }

    setBrandsData([]);
    setIsLoading(true);

    const fetchBrands = async () => {
      if (activeCategory?.id) {
        try {
          const response = await axios.get(`${BRAND_URL}/list`, {
            params: { is_featured_brand: 1, category_ids: activeCategory.id },
          });
          setBrandsData(response.data.entities);
        } catch (err) {
          console.error('Failed to fetch brands:', err);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchBrands();
  }, [activeCategory, NavCategoriesTemplate]);

  return (
    <div>
      {SelectedTemplate ? (
        <SelectedTemplate
          position={position}
          settingData={settingData}
          activeCategory={activeCategory}
          brandsData={brandsData}
          isLoading={isLoading}
        />
      ) : (
        skeleton()
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  settingData: state.settingData.ecom.basic,
});

export default connect(mapStateToProps)(NavCategories);
