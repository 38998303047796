import React from "react";
import { Collapse } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import defaultApplogoIcon from "./../../../src/assets/images/image 5.png";
import appStoreImg from "./../../assets/images/image 78.png";
import googlePlayImage from "./../../assets/images/image 79.png";
import twentyOnePlusImage from "./../../assets/images/image 76.png";

import "./style.css";
import { useHistory, Link } from "react-router-dom";

const { Panel } = Collapse;
const Footer = ({ settingData }) => {
  const getValue = (value, extraValue = "N/A") => {
    return value || extraValue;
  };
  const isShowHelpFullLinks =
    settingData?.custom_page_footer?.some(
      (custom_footer) => custom_footer.label === "About Us"
    ) ||
    settingData?.custom_page_footer?.some(
      (custom_footer) => custom_footer.label === "Contact Us"
    );
  const history = useHistory();
  const socialIcons = [
    {
      icon: "media/icons/twitter-icon.svg",
      value: getValue(settingData.twitter, ""),
    },
    {
      icon: "media/icons/instagram.svg",
      value: getValue(settingData.instagram, ""),
    },
    {
      icon: "media/icons/facebook.svg",
      value: getValue(settingData.facebook, ""),
    },
    {
      icon: "media/icons/youtube.svg",
      value: getValue(settingData.youtube, ""),
    },
  ];
  const handleClick = (url) => {
    if (!url) {
      console.error("URL is undefined or empty");
      return;
    }
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      if (url.startsWith("www.")) {
        url = "https://" + url;
      } else {
        console.error("Invalid URL format");
        return;
      }
    }

    window.open(url, "_blank");
  };

  return (
    <div className="footer">
      <div className="row-one">
        <div className="column-one">
          <div className="first-half">
            <img
              className="logo-footer"
              alt="Mask group"
              src={settingData.footer}
            />
            <div
              className="discription-footer"
              dangerouslySetInnerHTML={{
                __html: settingData.footer_description
                  ? settingData.footer_description
                  : "",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "20px", flexDirection: "row" }}>
            {socialIcons
              .filter((el) => el.value)
              .map(({ value, icon }) => (
                <img
                  src={`${process.env.PUBLIC_URL}/${icon}`}
                  onClick={() => handleClick(value)}
                  style={{ cursor: "pointer" }} // Optional, to show it's clickable
                  alt="Social Icon"
                />
              ))}
          </div>
        </div>
        <div className="columns desktop-view">
          <div className="column-two">
            <p className="headings">Helpful Links</p>
            <p
              className="content"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("")}
            >
              Home
            </p>
            <p
              className="content"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/shop")}
            >
              Shop
            </p>
            {settingData?.custom_page_footer?.some(
              (custom_footer) => custom_footer.label === "About Us"
            ) && (
              <p
                className="content"
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/about")}
              >
                About Us
              </p>
            )}

            {settingData?.custom_page_footer?.some(
              (custom_footer) => custom_footer.label === "Contact Us"
            ) && (
              <p
                className="content"
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/contact")}
              >
                Contact Us
              </p>
            )}

            {settingData?.custom_page_footer
              ?.filter(
                (custom_footer) =>
                  custom_footer.label !== "Contact Us" &&
                  custom_footer.label !== "About Us"
              )
              .map((custom_footer) => (
                <p className="content" key={custom_footer.label}>
                  <Link
                    className="content"
                    to={process.env.PUBLIC_URL + `/${custom_footer.slug}`}
                  >
                    {custom_footer.label}
                  </Link>
                </p>
              ))}

            {/* {settingData.custom_page_footer.map((custom_footer) => (
              <div style={{color:"#B3B3B3", display:"flex", flexDirection:"column", fontSize:"16px"}}>
                {custom_footer.label}
              </div>
            {/* <p className="content">FAQ</p> */}
            {/* <p className="content">Refund Policy</p> */}
            {/* <p className="content">Shipping&nbsp;&nbsp; Policy</p> */}
            {/* <p className="content">Privacy Policy</p> */}
            {/* <p className="content">Terms &amp; Conditions</p> */}
          </div>
          {settingData.address &&
          <div className="column-three">
            {/* <div dangerouslySetInnerHTML={{
              __html: settingData.address ? settingData.address : "",
            }}/> */}
            {settingData.show_address && settingData.address && (
              <>
                <p className="headings">Get In Touch</p>
                <p className="content">
                  {settingData.address ? settingData.address : ""}
                </p>
              </>
            )}
            {/* <p
              className="content"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/contact")}
            >
              Contact Us
            </p> */}
          </div>
          }
          {(settingData.google_play_store || settingData.apple_app_store) && (
            <div className="column-four">
              <p className="headings">Get the Mobile Apps</p>
              <p className="content">
                Shop on the go, track orders with the app.
              </p>
              <div className="download">
                {settingData.apple_app_store && (
                  <img
                    className="play-store"
                    alt="Apple Store"
                    src={googlePlayImage}
                    onClick={() => handleClick(settingData.apple_app_store)}
                  />
                )}
                {settingData.google_play_store && (
                  <img
                    className="play-store"
                    alt="Google Play Store"
                    src={appStoreImg}
                    onClick={() => handleClick(settingData.google_play_store)}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div className="mobile-view">
          <Collapse
            bordered={false}
            expandIconPosition={"right"}
            style={{ backgroundColor: "none" }}
          >
            { isShowHelpFullLinks &&
              <Panel header="Helpful Links" key="1">
                <div className="column-two">
                  <p
                    className="content"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("")}
                  >
                    Home
                  </p>
                  <p
                    className="content"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/shop")}
                  >
                    Shop
                  </p>
                  {settingData?.custom_page_footer?.some(
                    (custom_footer) => custom_footer.label === "About Us"
                  ) && (
                    <p
                      className="content"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/about")}
                    >
                      About Us
                    </p>
                  )}

                  {settingData?.custom_page_footer?.some(
                    (custom_footer) => custom_footer.label === "Contact Us"
                  ) && (
                    <p
                      className="content"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push("/contact")}
                    >
                      Contact Us
                    </p>
                  )}

                  {settingData?.custom_page_footer
                    ?.filter(
                      (custom_footer) =>
                        custom_footer.label !== "Contact Us" &&
                        custom_footer.label !== "About Us"
                    )
                    .map((custom_footer) => (
                      <p className="content" key={custom_footer.label}>
                        <Link
                          className="content"
                          to={process.env.PUBLIC_URL + `/${custom_footer.slug}`}
                        >
                          {custom_footer.label}
                        </Link>
                      </p>
                    ))}
                </div>
              </Panel>
            }
            {settingData.show_address && settingData.address && (
              <Panel header="Get In touch" key="2">
                <div className="column-three">
                  <p className="content">
                    {settingData.address ? settingData.address : ""}
                  </p>
                  {/* <p className="content">Contact Us</p> */}
                </div>
              </Panel>
            )}
            {
              (settingData.google_play_store && (
                <Panel header="Get the Mobile Apps" key="3">
                  <div className="column-four">
                    <p className="content">
                      Shop on the go, track orders with the app.
                    </p>
                    <div className="download">
                      {settingData.apple_app_store && (
                        <img
                          className="play-store"
                          alt="Apple Store"
                          src={googlePlayImage}
                          onClick={() =>
                            handleClick(settingData.apple_app_store)
                          }
                        />
                      )}
                      {settingData.google_play_store && (
                        <img
                          className="play-store"
                          alt="Google Play Store"
                          src={appStoreImg}
                          onClick={() =>
                            handleClick(settingData.google_play_store)
                          }
                        />
                      )}
                    </div>
                  </div>
                </Panel>
              ))}
          </Collapse>
        </div>
      </div>
      <div
        className="disclaimer-footer"
        dangerouslySetInnerHTML={{
          __html: settingData.footer_disclaimer
            ? settingData.footer_disclaimer
            : "",
        }}
      />
      <div className="copyrights">
        <div className="border"></div>
        <div className="first-half-one">
          <div
            className="content-one"
            style={{ display: "flex", color: "var(--style-footer-text-color)" }}
          >
            <span style={{ display: "flex", whiteSpace: "nowrap" }}>
              &copy; {new Date().getFullYear()}{" "}
            </span>
            <div style={{ marginLeft: "5px" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: settingData.copyrights_description
                    ? settingData.copyrights_description
                    : "",
                }}
              />
            </div>
          </div>
          <img className="twenty-plus" alt="Image" src={twentyOnePlusImage} />
        </div>
      </div>
    </div>
  );
};
Footer.propTypes = {
  settingData: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    settingData: state.settingData.ecom.basic,
  };
};

export default connect(mapStateToProps)(Footer);
