import React, { useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { logoutWithWithNormal } from "../../../redux/actions/loginActions";
import { getMenuItems } from "../../utils/menuItems";
import { handleUserLogout } from "../../utils/authUtils";
import CustomTextForAgeRestriction from "../../../components/age-restriction-section/CustomTextForAgeRestriction";

import HeaderOne from '../../../wrappers/header/HeaderOne';
import HeaderTemplate1 from './template1';
import HeaderTemplate2 from './template2';
import HeaderTemplate3 from './template3';
import HeaderTemplate4 from './template4';
import TempBanner from '../tempBanner/tempBanner';

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function() {
  return this
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const Header = ({ settingData, categoryTreeData, templateStyles, brandsData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation()
  const { currentState, cartData, currency } = useSelector(
    (state) => ({
      currentState: state.userData,
      cartData: state.cartData,
      currency: state.settingData?.["admin-panel"]?.basic || {},
    }),
    shallowEqual
  );

  const { logout } = currentState;
  if (logout) {
    history.push(`/login`);
    dispatch(logoutWithWithNormal(false));
  }

  const profileItems = getMenuItems(history, location, () => handleUserLogout(history));

  const templates = {
    'default': HeaderOne,
    '1': HeaderTemplate1,
    '2': HeaderTemplate2,
    '3': HeaderTemplate3,
    '4': HeaderTemplate4,
  };

  const skeleton = () => (
    <div className="tw-flex tw-items-center tw-p-4 tw-bg-gray-100 tw-animate-pulse tw-space-x-4 tw-absolute tw-w-full tw-top-0 tw-h-28">
      <div className="tw-w-24 tw-h-8 tw-bg-gray-300 tw-rounded"></div>
      <div className="tw-flex-1 tw-h-8 tw-bg-gray-300 tw-rounded"></div>
      <div className="tw-flex tw-space-x-4">
        <div className="tw-w-8 tw-h-8 tw-bg-gray-300 tw-rounded"></div>
        <div className="tw-w-8 tw-h-8 tw-bg-gray-300 tw-rounded"></div>
        <div className="tw-w-8 tw-h-8 tw-bg-gray-300 tw-rounded"></div>
      </div>
    </div>
  );
  
  const navTemplate = templateStyles?.nav_template || settingData?.nav_template || 'default';
  const SelectedTemplate = templates[navTemplate] || skeleton;

  const ageRestrictionRef = useRef(null);
  const tempBannerRef = useRef(null);
  const headerRef = useRef(null); // Added header ref

  // Set Height and Scroll Effect
  useEffect(() => {
    let previousHeaderHeight = 0;
    let previousAgeRestrictionHeight = 0;
    let previousTempBannerHeight = 0;
  
    const updateParentHeight = () => {
      if (!headerRef.current) return;
  
      const defaultHeight = 107;
      const headerElement =
        headerRef.current?.querySelector(".header-tmpl") || null;
  
      const headerHeight = headerElement?.offsetHeight || defaultHeight;
      const ageRestrictionHeight = ageRestrictionRef.current
        ? ageRestrictionRef.current.offsetHeight
        : 0;
      const tempBannerRefHeight = tempBannerRef.current
        ? tempBannerRef.current.offsetHeight
        : 0;
  
      if (
        headerHeight !== previousHeaderHeight ||
        ageRestrictionHeight !== previousAgeRestrictionHeight ||
        tempBannerRefHeight !== previousTempBannerHeight
      ) {
        previousHeaderHeight = headerHeight;
        previousAgeRestrictionHeight = ageRestrictionHeight;
        previousTempBannerHeight = tempBannerRefHeight;
  
        headerRef.current.style.height = `${headerHeight}px`;
        headerElement.style.top = `${ageRestrictionHeight + tempBannerRefHeight}px`;
      }
  
      const handleScroll = () => {
        const scrollY = window.scrollY;
        const offsetHeight = ageRestrictionHeight + tempBannerRefHeight;
  
        if (scrollY < offsetHeight) {
          headerElement.style.top = `${offsetHeight - scrollY}px`;
        } else {
          headerElement.style.top = `0`;
        }
      };
  
      window.addEventListener("scroll", handleScroll);
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    };
  
    const handleResize = () => {
      window.requestAnimationFrame(updateParentHeight);
    };
  
    // MutationObserver to detect CSS changes or any style-related modifications
    const observeCSSChanges = () => {
      const observer = new MutationObserver(() => {
        updateParentHeight();
      });
  
      observer.observe(document.documentElement, {
        attributes: true,
        attributeFilter: ["style", "class"],
        subtree: true,
      });
  
      return observer;
    };
  
    // ResizeObserver for resizing
    const resizeObserver = new ResizeObserver(handleResize);
  
    if (headerRef.current) {
      resizeObserver.observe(headerRef.current);
    }
    if (ageRestrictionRef.current) {
      resizeObserver.observe(ageRestrictionRef.current);
    }
  
    const mutationObserver = observeCSSChanges();
  
    window.addEventListener("resize", handleResize);
  
    // Initial update after rendering
    updateParentHeight();
  
    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
      window.removeEventListener("resize", handleResize);
    };
  }, [navTemplate, SelectedTemplate]);

  return (
    <div>
      {settingData?.show_temp_banner && (
        <div ref={tempBannerRef}>
          <TempBanner/>
        </div>
      )}
      {settingData?.show_ticker && (
        <div ref={ageRestrictionRef}>
          <CustomTextForAgeRestriction text={settingData?.ticker_text} />
        </div>
      )}
      {SelectedTemplate ? (
        <div ref={headerRef}>
          <SelectedTemplate
            settingData={settingData}
            categoryTreeData={categoryTreeData}
            brandsData={brandsData}
            currentState={currentState}
            profileItems={profileItems}
            cartData={cartData}
            currency={currency}
          />
        </div>
      ) : (
        skeleton
      )}
    </div>
  );
};

Header.propTypes = {
  settingData: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    settingData: state.settingData.ecom.basic,
    categoryTreeData: state.categoryData.entities,
    brandsData: state.brandsData,
  };
};

export default connect(mapStateToProps)(Header);
