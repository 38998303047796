export function getImages(settingData, prefix) {
  return Object.keys(settingData).reduce((acc, key) => {
    if (key.startsWith(prefix) && !key.includes('_url')) {
      acc.push({
        image: settingData[key],
        url: settingData[`${key}_url`] || "",
      });
    }
    return acc;
  }, []);
}
