import React, { useState, useEffect } from 'react';
import Brands from './components/brands';

const NavCategoriesTemplate5 = ({ position, activeCategory, brandsData, isLoading }) => {
  const MIN_WIDTH = 600;
  const RIGHT_OFFSET = 20;

  const [expandedKeys, setExpandedKeys] = useState([activeCategory.id]);

  useEffect(() => {
    // Ensure the first parent (activeCategory) is always expanded
    setExpandedKeys([activeCategory.id]);
  }, [activeCategory]);

  const handleExpandClick = (key, e) => {
    e.stopPropagation(); // Prevent triggering href link
    setExpandedKeys((prevKeys) =>
      prevKeys.includes(key) ? prevKeys.filter((k) => k !== key) : [...prevKeys, key]
    );
  };

  const subCategories = activeCategory?.children ?? [];
  if (!Object.keys(activeCategory).length || (!brandsData.length && !subCategories.length)) return null;

  const renderCategoryPanel = (category, depth = 0) => {
    const isExpanded = expandedKeys.includes(category.id);

    return (
      <div
        key={category.id}
        className={`tw-relative tw-pr-4 tw-w-full categories-accordion ${depth === 0 ? 'first-parent-class' : ''}`}
      >
        <div 
          onClick={(e) => depth > 0 && handleExpandClick(category.id, e)}
          className="tw-relative tw-pr-4 tw-flex-row"
        >
          <a
            href={`/shop/${category.slug}`}
            className={`
              tw-flex-grow tw-no-underline tw-decoration-transparent
              ${depth === 0 ? 'tw-text-lg color-primary tw-font-semibold tw-no-underline tw-decoration-transparent tw-cursor-auto' : ''}
              ${depth === 1 ? 'tw-font-semibold hover:tw-underline tw-cursor-pointer' : ''}
            `}
            onClick={(e) => {
              e.preventDefault();
              localStorage.setItem("parentCategory", activeCategory.id);
              if(depth !== 0) window.location.href = `/shop/?filter_cat=${category.slug}`;
              e.stopPropagation()
            }}
          >
            {category.name?.capitalize()}
          </a>
          {depth !== 0 && category.children && category.children.length > 0 && (
            <span className={`arrow-icon tw-ml-auto tw-absolute tw-right-0 tw-cursor-pointer ${isExpanded ? 'tw-rotate-180' : ''}`}>
              <svg
                className="tw-w-4 tw-h-4 tw-inline-block tw-rotate-90 tw-origin-center"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7"></path>
              </svg>
            </span>
          )}
        </div>
        {isExpanded && category.children && category.children.length > 0 && (
          <div
            className={`tw-flex tw-flex-col tw-gap-4 tw-my-4 ${
              depth >= 2 ? 'tw-ml-1' : ''
            } ${depth >= 1 ? 'tw-items-top -tw-mr-4' : ''}`}
          >
            {category.children.map((subCategory) => (
              <div
                key={subCategory.id}
                className={`tw-flex ${
                  depth >= 1 ? 'tw-flex-row tw-items-top -tw-mr-4' : 'tw-flex-col'
                } tw-gap-4`}
              >
                {depth >= 1 && (
                  <span className="expand-child tw-mt-1.5">
                    <svg width="4" height="4" viewBox="0 0 4 4" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="2" cy="2" r="2" fill="currentColor" />
                    </svg>
                  </span>
                )}
                {renderCategoryPanel(subCategory, depth + 1)}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="mega-menu"
      style={
        position && {
          top: position.top,
          left: window.innerWidth - position.left < MIN_WIDTH ? window.innerWidth - MIN_WIDTH : position.left,
          right: RIGHT_OFFSET,
          bottom: 'auto',
          width: MIN_WIDTH - RIGHT_OFFSET,
        }
      }
    >
      <div className="dropdown-content" style={{ gridTemplateColumns: '1fr 1fr' }}>
        {renderCategoryPanel(activeCategory, 0)}
        
        <div className="brand-info">
          <div className="brand-title tw-text-lg">Brands</div>
          {isLoading ? <span className='brand-alt'>Loading Brands...</span> : (<Brands brandsData={brandsData} />)}
        </div>
      </div>
    </div>
  );
};

export default NavCategoriesTemplate5;
