import React, { useState } from 'react';

import Categories from './components/categories';
import Profile from './components/profile';
import Cart from './components/cart';
import Navigation from './components/navigation';
import Search from './components/search';
import { Link } from "react-router-dom";
import MobileNavigation from './components/mobileNavigation';

const HeaderTemplate2 = ({settingData, categoryTreeData, currentState, profileItems, cartData, currency, brandsData}) => {
  const [isActionsVisible, setIsActionsVisible] = useState(false);

  const toggleActionsVisibility = () => {
    setIsActionsVisible((prev) => !prev);
  };

  const actionsVisibilityClass = isActionsVisible ? 'tw-flex-col tw-gap-y-4 tw-flex-wrap' : '!tw-hidden lg:!tw-flex';

  return (
    <header class="header-tmpl">
      <nav aria-label="Global">
      <div className="tw-flex lg:tw-hidden">
          <button 
            type="button" 
            onClick={toggleActionsVisibility}
            className="-tw-m-2.5 tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-p-2.5 tw-text-gray-700"
          >
            <span className="tw-sr-only">Open main menu</span>
            <svg className="tw-h-6 tw-w-6 mobile-menu-bar" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>

        {isActionsVisible && (
          <MobileNavigation
            setIsActionsVisible={setIsActionsVisible}
            categoryTreeData={categoryTreeData}
            settingData={settingData}
            currentState={currentState}
            profileItems={profileItems}
          />
        )}

        <div class="header-branding">
          <Link to={process.env.PUBLIC_URL + "/"}>
            <span class="tw-sr-only">{settingData.ecom_title}</span>
            <img class="" src={settingData.logo} alt={settingData.ecom_title}/>
          </Link>
        </div>

        <div className="lg:!tw-hidden header-actions !tw-basis-0">
          <Cart cartData={cartData}/>
        </div>

        <Navigation className={`header-actions ${actionsVisibilityClass}`} settingData={settingData}/>

        <div className={`header-actions ${actionsVisibilityClass}`}>
          <Search
            settingData={settingData}
            currency={currency}
            buttonRight={true}
            buttonClassName={'accent-button'}
            wrapperClassName={'!tw-border-gray-100 !tw-bg-white'}
            parentClassName={'lg:tw-w-80'}
          />
          <Cart cartData={cartData} showText={'View Cart'}/>
          <Profile
            currentState={currentState}
            profileItems={profileItems}
            showSignup={true}
            showText={true}
          />
        </div>
      </nav>
      <Categories categoryTreeData={categoryTreeData} brandsData={brandsData} className={'max-lg:!tw-hidden'}/>
      <div className='mobile-search'>
        <Search settingData={settingData} currency={currency}/>
      </div>
    </header>
  );
};

export default HeaderTemplate2;
