import React, { useState } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getImages } from '../banner/utils/utils';
import BannerCarousel from '../banner/components/carousel'

const TempBanner = ({ settingData }) => {
const [isLoading, setLoading] = useState(true);
  const SliderImages = getImages(settingData, 'temp_banner_image')

  return (
    <div className='temp-banner'>
      <div className='aspect-ratio'>
        <div className='aspect-ratio-content'>
          <BannerCarousel images={SliderImages} fallBackImage={settingData.logo} isLoading={isLoading} setLoading={setLoading} />
        </div>
      </div>
    </div>
  );
};

TempBanner.propTypes = {
  settingData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  settingData: state.settingData.ecom.basic,
});

export default connect(mapStateToProps)(TempBanner);
