import { Field, Formik } from "formik";
import React, { useMemo, useEffect } from "react";
import {
  AGREE_TYPE_SALE,
  ALPHA_NUMERIC_REG_EXP,
  SHIPPING_TYPE,
  ZIP_CODE_LENGTH,
} from "../../constant/Constants";
import { Input } from "../../../components/form/Input";
import { Select } from "../../../components/form/Select";
import { store } from "../../..";
import { Radio, Spin } from "antd";
import {
  fetchBillingCities,
  fetchCities,
  fetchCounties,
  fetchStates,
} from "../../../redux/actions/commonActions";
import { useSelector } from "react-redux";
import { YesNoSelect } from "../../../components/form/YesNoSelect";
import Button from "../../../components/utils/Button";
import {
  IS_REQUIRED_MESSAGE,
  MAX_MESSAGE,
  MIN_MESSAGE,
  backendFormatDateTime,
  dateStringApplyOffSetNew,
} from "../../constant/utils";
import * as Yup from "yup";
import PhoneInput from "../../../components/form/PhoneInput";
import { useToasts } from "react-toast-notifications";
import { useMainUIContext } from "../../../UIContext";

function ShippingDetails({
  saleOrderPayload,
  setSalePayload,
  userDetail,
  settings,
  onNext,
  checkoutCalculation,
  formRef,
}) {
  const { states, cities, billingStates, loading, counties } = useSelector(
    (state) => state.generalData
  );
  const { addToast } = useToasts();
  const today = new Date();
  const numberOfDaysToAdd = 0;
  const date = today.setDate(today.getDate() + numberOfDaysToAdd);
  const defaultValue = new Date(date).toISOString().split("T")[0];
  useEffect(() => {
    if (formRef.current.setValues) {
      formRef.current.setValues(checkoutShippingDetails)
    }
  }, [null])
  const mainUIContext = useMainUIContext();
  const mainUIProps = useMemo(() => {
    return {
      checkoutShippingDetails: mainUIContext.checkoutShippingDetails,
      setCheckoutShippingDetails: mainUIContext.setCheckoutShippingDetails,
    };
  }, [mainUIContext]);
  const { checkoutShippingDetails, setCheckoutShippingDetails } = mainUIProps;

  useEffect(() => {
    setCheckoutShippingDetails({
      ...saleOrderPayload,
      shipping_detail: {
        ...userDetail.user.customer_shipping_details,
      },
      billing_detail: {
        ...userDetail.user.customer_billing_details,
      },
    });
  }, [])

  const setShippingBillingDetailInContext = (parent, child, value) => {
    setCheckoutShippingDetails((prev) => { return { ...prev, [parent]: { ...prev[parent], [child]: value } } })
  }

  return (
    <div>
      <Formik
        initialValues={{
          ...saleOrderPayload,
          shipping_detail: {
            ...userDetail.user.customer_shipping_details,
          },
          billing_detail: {
            ...userDetail.user.customer_billing_details,
          },
        }}
        innerRef={formRef}

        validationSchema={() =>
          Yup.lazy((values) => {
            return Yup.object().shape({
              // pickup_delivery_date: Yup.date().required(
              //   IS_REQUIRED_MESSAGE("Pickup Delivery Date")
              // ),
              // dispatch_date: Yup.string(),
              shipping_type: Yup.number()
                .required("Shipping Type is required")
                .label("Shipping Type"),
              shipping_detail: Yup.object().when("shipping_type", {
                is: (type) => type == SHIPPING_TYPE.DELIVERY.value,
                then: Yup.object().shape({
                  name: Yup.string()
                    .matches(ALPHA_NUMERIC_REG_EXP)
                    .max(100, MAX_MESSAGE("Name", 100))
                    .nullable()
                    .required(IS_REQUIRED_MESSAGE("Name")),
                  company_name: Yup.string()
                    .min(2, MIN_MESSAGE("Company name", 2))
                    .required(IS_REQUIRED_MESSAGE("Company name"))
                    .nullable(),
                  telephone_num: Yup.string()
                    // .min(15, "Phone number is not valid")
                    .required(IS_REQUIRED_MESSAGE("Phone number"))
                    .nullable(),
                  address: Yup.string()
                    .max(90, MAX_MESSAGE("Address", 90))
                    .required(IS_REQUIRED_MESSAGE("Address"))
                    .nullable(),
                  country: Yup.string()
                    .required(IS_REQUIRED_MESSAGE("Country"))
                    .nullable(),
                  city: Yup.string()
                    .required(IS_REQUIRED_MESSAGE("City"))
                    .nullable(),
                  state: Yup.string()
                    .required(IS_REQUIRED_MESSAGE("State"))
                    .nullable(),
                  zip_code: Yup.string()
                    .max(
                      ZIP_CODE_LENGTH,
                      MAX_MESSAGE("Zip code", ZIP_CODE_LENGTH)
                    )
                    .required(IS_REQUIRED_MESSAGE("Zip code"))
                    .nullable(),
                  tax_jurisdiction: Yup.string()
                    .required(IS_REQUIRED_MESSAGE("Tax Jurisdiction"))
                    .nullable(),
                  is_billing_same: Yup.string()
                    .required(IS_REQUIRED_MESSAGE("Is billing/shipping same"))
                    .default("Y")
                    .nullable(),
                }),
                otherwise: Yup.object(),
              }),
              billing_detail: Yup.object().when("shipping_detail", {
                is: (value) =>
                  value.is_billing_same === AGREE_TYPE_SALE.Yes.value,
                then: Yup.object(),
                otherwise: Yup.object().shape({
                  // name: Yup.string()
                  //   .matches(ALPHA_NUMERIC_REG_EXP)
                  //   .max(100, MAX_MESSAGE("Name", 100))
                  //   .required(IS_REQUIRED_MESSAGE("Name"))
                  //   .nullable(),

                  // company_name: Yup.string()
                  //   .min(2, MIN_MESSAGE("Company name", 2))
                  //   .required(IS_REQUIRED_MESSAGE("Company name"))
                  //   .nullable(),
                  // telephone_num: Yup.string()
                  //   .min(15, "Phone number is not valid")
                  //   .required(IS_REQUIRED_MESSAGE("Phone number"))
                  //   .nullable(),
                  address: Yup.string()
                    .max(90, MAX_MESSAGE("Address", 90))
                    .required(IS_REQUIRED_MESSAGE("Address"))
                    .nullable(),
                  country: Yup.string()
                    .required(IS_REQUIRED_MESSAGE("Country"))
                    .nullable(),
                  city: Yup.string()
                    .required(IS_REQUIRED_MESSAGE("City"))
                    .nullable(),
                  state: Yup.string()
                    .required(IS_REQUIRED_MESSAGE("State"))
                    .nullable(),
                  // zip_code: Yup.string()
                  //   .required(IS_REQUIRED_MESSAGE("Zip code"))
                  //   .max(
                  //     ZIP_CODE_LENGTH,
                  //     MAX_MESSAGE("Zip code", ZIP_CODE_LENGTH)
                  //   )
                  //   .nullable(),
                }),
              }),
            });
          })
        }
        onSubmit={(values) => {
          if (!values.shipping_detail.address_two) {
            delete values?.shipping_detail?.address_two;
          }
          const parsedValue = { ...values, dispatch_date: dateStringApplyOffSetNew(Date.now(), settings?.timezone), pickup_delivery_date: dateStringApplyOffSetNew(values.pickup_delivery_date, settings?.timezone) }
          setSalePayload(parsedValue);
          setCheckoutShippingDetails(parsedValue)
          onNext();
          checkoutCalculation(values)
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, isSubmitting }) => (
          <div className="customer-info-container">
            <div className="customer-info-wrapper">
              <h3 className="checkout_headings">Delivery Type</h3>
              <span className="cursor-pointer">
              </span>
            </div>
            <div>
              <div className="customized-row">
                <div className="container">
                  {/* Shipping Address Details */}

                  <div
                    className="customized-row"
                    style={{ marginBottom: "25px" }}
                  >
                    <Radio.Group
                      className="custom-radio-group"
                      onChange={(e) => {
                        setFieldValue("shipping_type", e.target.value);
                        setSalePayload((prev) => ({
                          ...prev,
                          shipping_type: e.target.value,
                        }));
                      }}
                      value={values.shipping_type}
                    >
                      <Radio className="mt-2 mb-2" value={SHIPPING_TYPE.PICKUP.value}>{SHIPPING_TYPE.PICKUP.type}</Radio>
                      <Radio className="mt-2 mb-2" value={SHIPPING_TYPE.DELIVERY.value}>{SHIPPING_TYPE.DELIVERY.type}</Radio>
                    </Radio.Group>
                    {/* <div className="checkout-input-feilds">
                          <Field
                            // label="Shipping Type*"
                            label={
                              <span className="forms-labels">
                                Shipping Type<span className="asterisk">*</span>
                              </span>
                            }
                            name="shipping_type"
                            className="selector-field"
                            placeholder="Please Select Shipping Type"
                            options={Object.values(SHIPPING_TYPE) || []}
                            onChange={(value) => {
                              setFieldValue("shipping_type", value);
                              setSalePayload((prev) => ({
                                ...prev,
                                shipping_type: +value,
                              }));
                            }}
                            component={GeneralSelect}
                            customFeedbackLabel=" "
                          />
                        </div> */}
                  </div>

                  {+values.shipping_type === SHIPPING_TYPE.DELIVERY.value && (
                    <>
                      <div className="customized-row">
                        <div className="checkout-input-feilds">
                          <Field
                            type="text"
                            name="shipping_detail.name"
                            component={Input}
                            onChange={({ target: { value } }) => {
                              setFieldValue("shipping_detail.name", value)
                              setShippingBillingDetailInContext("shipping_detail", "name", value)
                            }}
                            inputProps={{
                              name: "shipping_detail.name",
                            }}
                            value={values.shipping_detail.name || ''}
                            placeholder="Contact Name*"
                            label={
                              <span className="forms-labels">
                                Contact Name<span className="asterisk">*</span>
                              </span>
                            }
                            className="customized-input"
                          />
                        </div>
                        <div className="checkout-input-feilds">
                          <Field
                            type="text"
                            name="shipping_detail.company_name"
                            component={Input}
                            inputProps={{
                              name: "shipping_detail.company_name",
                            }}
                            placeholder="Business Name"
                            onChange={({ target: { value } }) => {
                              setFieldValue("shipping_detail.company_name", value)
                              setShippingBillingDetailInContext("shipping_detail", "company_name", value)
                            }}
                            value={values?.shipping_detail?.company_name}
                            label={
                              <span className="forms-labels">
                                Business Name<span className="asterisk">*</span>
                              </span>
                            }
                            className="customized-input"
                          />
                        </div>
                        <div className="checkout-input-feilds">
                          <span className="forms-labels">
                            <label>Phone Number</label><span className="asterisk">*</span>
                          </span>
                          <PhoneInput
                            // component={Input}
                            name="shipping_detail.telephone_num"
                            onChange={(value) => {
                              setFieldValue(
                                "shipping_detail.telephone_num",
                                value
                              );
                              setShippingBillingDetailInContext("shipping_detail", "telephone_num", value)
                            }}
                            value={
                              values?.shipping_detail?.telephone_num || ""
                            }
                            className="custom-phone-input"
                            inputProps={{
                              name: "shipping_detail.telephone_num",
                              // autoFocus: true,
                            }}
                          />
                        </div>
                        <div
                          // className="customized-row checkout-input-feilds"
                          // style={{ display: "flex", flexDirection: "column" }}
                          className="checkout-input-feilds"
                        >
                          <span className="forms-labels">
                            <label>Address</label><span className="asterisk">*</span>
                          </span>
                          <Field
                            type="text"
                            name="shipping_detail.address"
                            value={values?.shipping_detail?.address}
                            onChange={({ target: { value }}) => {
                              setFieldValue(
                                "shipping_detail.address",
                                value
                              );
                              setShippingBillingDetailInContext("shipping_detail", "address", value)
                            }}
                            component={Input}
                            className="customized-input"
                            placeholder="Address"
                          />
                        </div>
                        <div className="checkout-input-feilds">
                          <Field
                            name="shipping_detail.country"
                            label={
                              <span className="forms-labels">
                                Country<span className="asterisk">*</span>
                              </span>
                            }
                            value={values?.shipping_detail?.country}
                            component={Select}
                            className="selector-field"
                            onChange={(e) => {
                              let value = e.value || e 
                              console.log(e);
                              setFieldValue(
                                "shipping_detail.country",
                                value
                              );
                              setShippingBillingDetailInContext("shipping_detail", "country", value)
                              setFieldValue("shipping_detail.state", "");
                              setFieldValue("shipping_detail.city", "");
                              setFieldValue("shipping_detail.county", "");
                              setFieldValue(
                                "shipping_detail.tax_jurisdiction",
                                ""
                              );
                              // setShippingValues(e.value);
                              store.dispatch(
                                fetchStates({
                                  country: e.value || e,
                                })
                              );
                            }}
                          />
                        </div>
                        <div className="checkout-input-feilds">
                          <Spin spinning={loading}>
                            <Field
                              name="shipping_detail.state"
                              label={
                                <span className="forms-labels">
                                  State<span className="asterisk">*</span>
                                </span>
                              }
                              component={Select}
                              value={values?.shipping_detail?.state}
                              className="selector-field"
                              options={states}
                              onChange={(e) => {
                                let value = e.value || e
                                setFieldValue(
                                  "shipping_detail.state",
                                  value
                                );
                                setShippingBillingDetailInContext("shipping_detail", "state", value)
                                setFieldValue("shipping_detail.city", "");
                                setFieldValue("shipping_detail.county", "");
                                setFieldValue(
                                  "shipping_detail.tax_jurisdiction",
                                  e.value || e
                                );
                                store.dispatch(
                                  fetchCities({
                                    country:
                                      values.shipping_detail.country ||
                                      "United States",
                                    state: e.value || e,
                                  })
                                );
                                store.dispatch(
                                  fetchCounties({
                                    country:
                                      values.shipping_detail.country ||
                                      "United States",
                                    state: e.value || e,
                                  })
                                );
                              }}
                              customFeedbackLabel=" "
                            />
                          </Spin>
                        </div>

                        {values.shipping_detail.country == "United States" && (
                          <div className="checkout-input-feilds">
                            <Spin spinning={loading}>
                              <Field
                                name="shipping_detail.county"
                                label={
                                  <span className="forms-labels">
                                    County<span className="asterisk">*</span>
                                  </span>
                                }
                                component={Select}
                                value={values?.shipping_detail?.county}
                                className="selector-field"
                                options={counties}
                                onChange={(e) => {
                                  let value = e.value || e
                                  setFieldValue(
                                    "shipping_detail.county",
                                    value
                                  );

                                  setShippingBillingDetailInContext("shipping_detail", "county", value)
                                }}
                                customFeedbackLabel=" "
                              />
                            </Spin>
                          </div>)}
                        <div className="checkout-input-feilds">
                          <Spin spinning={loading}>
                            <Field
                              name="shipping_detail.city"
                              label={
                                <span className="forms-labels">
                                  City<span className="asterisk">*</span>
                                </span>
                              }
                              value={values?.shipping_detail?.city}
                              component={Select}
                              className="selector-field"
                              options={cities}
                              onChange={(e) => {
                                let value = e.value || e
                                  setFieldValue(
                                    "shipping_detail.city",
                                    value
                                  );

                                  setShippingBillingDetailInContext("shipping_detail", "city", value)
                              }}
                            />
                          </Spin>
                        </div>
                        <div className="checkout-input-feilds">
                          <Field
                            type="text"
                            name="shipping_detail.zip_code"
                            value={values?.shipping_detail?.zip_code}
                            onChange={({ target: { value } }) => {
                              setFieldValue(
                                "shipping_detail.zip_code",
                                value
                              );

                              setShippingBillingDetailInContext("shipping_detail", "zip_code", value)
                            }}
                            label={
                              <span className="forms-labels">
                                Zip Code<span className="asterisk">*</span>
                              </span>
                            }
                            component={Input}
                            className="customized-input"
                            placeholder="Zip Code"
                          />
                        </div>
                        <div className="checkout-input-feilds">
                          <span className="forms-labels">
                            <label>Tax Jurisdiction</label><span className="asterisk">*</span>
                          </span>
                          <Field
                            name="shipping_detail.tax_jurisdiction"
                            value={values?.shipping_detail?.tax_jurisdiction}
                            // label="Tax Jurisdiction*"
                            component={Select}
                            options={states}
                            onChange={(e) => {
                              const newState = e.value || e;
                              setFieldValue(
                                "shipping_detail.tax_jurisdiction",
                                newState
                              );
                              setShippingBillingDetailInContext("shipping_detail", "tax_jurisdiction", newState)
                              store.dispatch(
                                fetchCities({
                                  country: values.country || "United States",
                                  state: e.value || e,
                                })
                              );
                            }}
                            customFeedbackLabel=" "
                            className="selector-field"
                          />
                        </div>
                        {/* <div className="checkout-input-feilds">
                            <Field
                              type="date"
                              name="pickup_delivery_date"
                              min={defaultValue}
                              defaultValue={defaultValue}
                              component={Input}
                              className="customized-input"
                              label={
                                <span className="forms-labels">
                                  Delivery Date
                                </span>
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  "pickup_delivery_date",
                                  e.target.value
                                );
                                // setShippingValues(e.target.value);
                              }}
                            />
                          </div> */}
                        {/* {!values.billing_detail.name &&  */}
                        <div className="yes-no-feild">
                          <Field
                            name="shipping_detail.is_billing_same"
                            label={
                              <span className="forms-labels">
                                Is Billing Address is Same
                                <span className="asterisk">*</span>
                              </span>
                            }
                            component={YesNoSelect}
                            value={values?.shipping_detail?.is_billing_same}
                            onChange={(e) => {
                              setFieldValue(e.target.name, e.target.value);
                              setShippingBillingDetailInContext("shipping_detail", "is_billing_same", e.target.value)
                            }}
                          />
                        </div>
                        {/* } */}
                        {/* {values.shipping_type == "2" ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "20px",
                                marginTop: "10px",
                              }}
                            >
                              <label style={{ marginRight: "10px" }}>
                                Update Details
                              </label>
                              <div>
                                <Switch
                                  name="update_shipping_billing"
                                  defaultChecked={
                                    values.update_shipping_billing
                                  }
                                  onChange={(value) => {
                                    setFieldValue(
                                      "update_shipping_billing",
                                      value
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          ) : null} */}


                        {values?.shipping_detail?.is_billing_same === "N" && (
                          <div className="container my-2">
                            <h5 className="billing-title">Billing Details</h5>
                            <div className="customized-row">
                              <div className="checkout-input-feilds">
                                <Field
                                  type="text"
                                  name="billing_detail.name"
                                  component={Input}
                                  onChange={(e) => {
                                    setFieldValue(e.target.name, e.target.value);
                                    setShippingBillingDetailInContext("billing_detail", "name", e.target.value)
                                  }}
                                  value={values?.billing_detail?.name}
                                  inputProps={{
                                    name: "billing_detail.name",
                                  }}
                                  placeholder="Contact Name*"
                                  label="Contact Name*"
                                  className="customized-input"
                                />
                              </div>
                              <div className="checkout-input-feilds">
                                <Field
                                  type="text"
                                  name="billing_detail.company_name"
                                  component={Input}
                                  onChange={(e) => {
                                    setFieldValue(e.target.name, e.target.value);
                                    setShippingBillingDetailInContext("billing_detail", "company_name", e.target.value)
                                  }}
                                  value={values?.billing_detail?.company_name}
                                  inputProps={{
                                    name: "billing_detail.company_name",
                                  }}
                                  placeholder="Business Name*"
                                  label="Business Name*"
                                  className="customized-input"
                                />
                              </div>
                              <div className="checkout-input-feilds">
                                <label>Phone Number*</label>
                                <PhoneInput
                                  // component={Input}
                                  name="billing_detail.telephone_num"
                                  onChange={(value) => {
                                    setFieldValue(
                                      "billing_detail.telephone_num",
                                      value
                                    );
                                    setShippingBillingDetailInContext("billing_detail", "telephone_num", value)
                                  }}
                                  value={
                                    values?.billing_detail?.telephone_num || ""
                                  }
                                  className="custom-phone-input"
                                  inputProps={{
                                    name: "billing_detail.telephone_num",
                                    // autoFocus: true,
                                  }}
                                />
                              </div>
                              <div className="checkout-input-feilds">
                                <Field
                                  type="text"
                                  name="billing_detail.address"
                                  component={Input}
                                  value={values?.billing_detail?.address}
                                  onChange={(e) => {
                                    setFieldValue(e.target.name, e.target.value);
                                    setShippingBillingDetailInContext("billing_detail", "address", e.target.value)
                                  }}
                                  className="customized-input"
                                  placeholder="Address"
                                  label={
                                    <span className="forms-labels">
                                      Address
                                      <span className="asterisk">*</span>
                                    </span>
                                  }
                                />
                              </div>

                              <div className="checkout-input-feilds">
                                <Spin spinning={loading}>
                                  <Field
                                    name="billing_detail.state"
                                    label={
                                      <span className="forms-labels">
                                        State
                                        <span className="asterisk">*</span>
                                      </span>
                                    }
                                    component={Select}
                                    className="selector-field"
                                    value={values?.billing_detail?.state}
                                    options={billingStates}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "billing_detail.state",
                                        e.value || e
                                      );
                                      setShippingBillingDetailInContext("billing_detail", "state", e.value || e)
                                      setFieldValue("billing_detail.city", "");
                                      setFieldValue("billing_detail.county", "");
                                      setFieldValue(
                                        "billing_detail.tax_jurisdiction",
                                        e.value || e
                                      );
                                      store.dispatch(
                                        fetchBillingCities({
                                          country:
                                            values.billing_detail.country ||
                                            "United States",
                                          state: e.value || e,
                                        })
                                      );
                                      store.dispatch(
                                        fetchCounties({
                                          country:
                                            values.billing_detail.country ||
                                            "United States",
                                          state: e.value || e,
                                        })
                                      );
                                    }}
                                  />
                                </Spin>
                              </div>
                              {values.billing_detail.country == "United States" && (
                                <div className="checkout-input-feilds">
                                  <Spin spinning={loading}>
                                    <Field
                                      name="billing_detail.county"
                                      label={
                                        <span className="forms-labels">
                                          County<span className="asterisk">*</span>
                                        </span>
                                      }
                                      component={Select}
                                      value={values?.billing_detail?.county}
                                      className="selector-field"
                                      options={counties}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "billing_detail.county",
                                          e.value || e
                                        );
                                        setShippingBillingDetailInContext("billing_detail", "county", e.value || e)
                                      }}
                                      customFeedbackLabel=" "
                                    />
                                  </Spin>
                                </div>)}
                              <div className="checkout-input-feilds">
                                <Spin spinning={loading}>
                                  <Field
                                    name="billing_detail.city"
                                    label={
                                      <span className="forms-labels">
                                        City
                                        <span className="asterisk">*</span>
                                      </span>
                                    }
                                    component={Select}
                                    className="selector-field"
                                    options={cities}
                                    value={values?.billing_detail?.city}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "billing_detail.city",
                                        e.value || e
                                      );
                                      setShippingBillingDetailInContext("billing_detail", "city", e.value || e)
                                    }}
                                  />
                                </Spin>
                              </div>
                              <div className="checkout-input-feilds">
                                <Field
                                  type="text"
                                  name="billing_detail.zip_code"
                                  label={
                                    <span className="forms-labels">
                                      Zip Code
                                      <span className="asterisk">*</span>
                                    </span>
                                  }
                                  component={Input}
                                  onChange={(e) => {
                                    setFieldValue(e.target.name, e.target.value);
                                    setShippingBillingDetailInContext("billing_detail", "zip_code", e.target.value)
                                  }}
                                  value={values?.billing_detail?.zip_code}
                                  className="customized-input"
                                  placeholder="Zip Code"
                                />
                              </div>
                              {/* <div className="checkout-input-feilds">
                                  <Field
                                    name="billing_detail.tax_jurisdiction"
                                    label="Tax Jurisdiction*"
                                    component={Select}
                                    options={states}
                                    onChange={(e) => {
                                      const newState = e.value || e;
                                      setFieldValue(
                                        "billing_detail.tax_jurisdiction",
                                        newState
                                      );
                                      store.dispatch(
                                        fetchCities({
                                          country:
                                            values.country || "United States",
                                          state: e.value || e,
                                        })
                                      );
                                    }}
                                    customFeedbackLabel=" "
                                    className="selector-field"
                                  />
                                </div> */}
                            </div>
                          </div>
                        )}

                        <div style={{ width: "100%" }}>
                          <label
                            className="forms-labels"
                            style={{ marginBottom: "5px" }}
                          >
                            Add Delivery Instructions
                          </label>
                          <Field
                            className="textarea"
                            component="textarea"
                            name="shipping_detail.delivery_instructions"
                            maxLength={120}
                            placeholder="Enter Delivery Instruction"
                            onChange={(e) => {
                              setFieldValue(e.target.name, e.target.value);
                              setShippingBillingDetailInContext("shipping_detail", "delivery_instructions", e.target.value)
                            }}
                            value={values?.shipping_detail?.delivery_instructions}
                          />
                          <p className="char-counter">
                            {values?.shipping_detail.delivery_instructions
                              ?.length || 0}
                            /120
                          </p>
                        </div>
                        <p className="checkout-page-msg">*Shipping and billing details will be updated upon placing the order.</p>
                      </div>
                    </>
                  )}
                  <div className="customized-row1">
                    <Spin spinning={isSubmitting} size="small" style={{ marginRight: '5px' }} >

                      <Button
                        label="Next"
                        onClick={() => {
                          handleSubmit();

                        }}
                        className="checkout-next"
                      >
                        Next
                      </Button>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default ShippingDetails;
